_
<template>
  <div>
    <v-data-table
      :loading="dataTableLoading"
      :no-data-text="$t('dataTable.NO_DATA')"
      :no-results-text="$t('dataTable.NO_RESULTS')"
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :items-per-page="5"
      :server-items-length="totalItems"
      class="elevation-1"
      :footer-props="{
        'items-per-page-text': $t('dataTable.ROWS_PER_PAGE'),
        'items-per-page-options': [5, 10, 25]
      }"
    >
      <template v-slot:top>
        <v-row>
          <v-col
            class="mt-3 pl-4"
            cols="12"
            sm="12"
            md="4"
          >
            <div class="text-left">
              <v-toolbar-title>{{ $t('cities.TITLE') }}</v-toolbar-title>
            </div>
          </v-col>
          <v-col
            class="px-3"
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              id="search"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('dataTable.SEARCH')"
              single-line
              hide-details
              clearable
              clear-icon="mdi-close"
            />
          </v-col>
          <v-col
            class="mb-2 mt-2 pr-2"
            cols="12"
            sm="6"
            md="4"
          >
            <ValidationObserver
              ref="observer"
              v-slot="{ invalid }"
              tag="form"
              @submit.prevent="submit()"
            >
              <v-dialog
                v-model="dialog"
                max-width="500px"
                content-class="dlgNewEditItem"
              >
                <template v-slot:activator="{ on }">
                  <div class="text-right">
                    <v-btn
                      color="secondary"
                      class="btnNewItem"
                      v-on="on"
                    >
                      <v-icon class="mr-2">
                        mdi-plus
                      </v-icon>
                      {{ $t('dataTable.NEW_ITEM') }}
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <template v-if="editedItem._id">
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <label for="createdAt">{{
                              $t('common.CREATED')
                            }}</label>
                            <div name="createdAt">
                              {{ getFormat(editedItem.createdAt) }}
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <label for="updatedAt">{{
                              $t('common.UPDATED')
                            }}</label>
                            <div name="updatedAt">
                              {{ getFormat(editedItem.updatedAt) }}
                            </div>
                          </v-col>
                        </template>
                        <v-col cols="12">
                          <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <v-text-field
                              id="name"
                              v-model="editedItem.name"
                              required
                              name="name"
                              :label="$t('cities.headers.NAME')"
                              :error="errors.length > 0"
                              :error-messages="errors[0]"
                              autocomplete="off"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red lighten3"
                      text
                      class="btnCancel"
                      @click="close"
                    >
                      {{ $t('dataTable.CANCEL') }}
                    </v-btn>
                    <v-btn
                      color="green lighten3"
                      text
                      class="btnSave"
                      :disabled="invalid"
                      @click="save"
                    >
                      {{ $t('dataTable.SAVE') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </ValidationObserver>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item._id`]="{ item }">
        <v-row class="justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                id="edit"
                icon
                v-on="on"
                @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('dataTable.EDIT') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                id="delete"
                icon
                v-on="on"
                @click="deleteItem(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('dataTable.DELETE') }}</span>
          </v-tooltip>
        </v-row>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getFormat(item.createdAt) }}
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ getFormat(item.updatedAt) }}
      </template>
      <template
        v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        {{ pageStart }} - {{ pageStop }}
        {{ $t('dataTable.OF') }}
        {{ itemsLength }}
      </template>
      <template v-slot:no-data>
        {{ $t('dataTable.NO_DATA') }}
      </template>
      <template v-slot:no-results>
        {{ $t('dataTable.NO_RESULTS') }}
      </template>
    </v-data-table>
    <ErrorMessage />
    <SuccessMessage />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getFormat, buildPayloadPagination } from '@/utils/utils.js'

  export default {
    metaInfo () {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t('cities.TITLE')} - %s`,
      }
    },
    data () {
      return {
        dataTableLoading: true,
        delayTimer: null,
        dialog: false,
        search: '',
        pagination: {},
        editedItem: {},
        defaultItem: {},
        fieldsToSearch: ['name'],
      }
    },
    computed: {
      formTitle () {
        return this.editedItem._id
          ? this.$t('dataTable.EDIT_ITEM')
          : this.$t('dataTable.NEW_ITEM')
      },
      headers () {
        return [
          {
            text: this.$i18n.t('dataTable.ACTIONS'),
            value: '_id',
            sortable: false,
            width: 100,
          },
          {
            text: this.$i18n.t('cities.headers.NAME'),
            align: 'left',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$i18n.t('common.CREATED'),
            align: 'left',
            sortable: true,
            value: 'createdAt',
          },
          {
            text: this.$i18n.t('common.UPDATED'),
            align: 'left',
            sortable: true,
            value: 'updatedAt',
          },
        ]
      },
      items () {
        return this.$store.state.adminCities.cities
      },
      totalItems () {
        return this.$store.state.adminCities.totalCities
      },
    },
    watch: {
      dialog (value) {
        return value ? true : this.close()
      },
      pagination: {
        async handler () {
          try {
            this.dataTableLoading = true
            await this.getCities(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false
          // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true,
      },
      async search () {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
          this.doSearch()
        }, 400)
      },
    },
    methods: {
      ...mapActions(['getCities', 'editCity', 'saveCity', 'deleteCity']),
      getFormat (date) {
        window.__localeId__ = this.$store.getters.locale
        return getFormat(date, 'iii, MMMM d yyyy, h:mm a')
      },
      async doSearch () {
        try {
          this.dataTableLoading = true
          await this.getCities(
            buildPayloadPagination(this.pagination, this.buildSearch()),
          )
          this.dataTableLoading = false
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
      buildSearch () {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      },
      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          const response = await this.$confirm(
            this.$t('common.DO_YOU_REALLY_WANT_TO_DELETE_THIS_ITEM'),
            {
              title: this.$t('common.WARNING'),
              buttonTrueText: this.$t('common.DELETE'),
              buttonFalseText: this.$t('common.CANCEL'),
              buttonTrueColor: 'red lighten3',
              buttonFalseColor: 'green',
            },
          )
          if (response) {
            this.dataTableLoading = true
            await this.deleteCity(item._id)
            await this.getCities(
              buildPayloadPagination(this.pagination, this.buildSearch()),
            )
            this.dataTableLoading = false
          }
        // eslint-disable-next-line no-unused-vars
        } catch (error) {
          this.dataTableLoading = false
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        }, 300)
      },
      async save () {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          try {
            this.dataTableLoading = true
            // Updating item
            if (this.editedItem._id) {
              await this.editCity(this.editedItem)
              await this.getCities(
                buildPayloadPagination(this.pagination, this.buildSearch()),
              )
              this.dataTableLoading = false
            } else {
              // Creating new item
              await this.saveCity({ name: this.editedItem.name })
              await this.getCities(
                buildPayloadPagination(this.pagination, this.buildSearch()),
              )
              this.dataTableLoading = false
            }
            this.close()
          // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
            this.close()
          }
        }
      },
    },
  }
</script>

<style>
table.v-table {
  max-width: none;
}
</style>
